.App {
  text-align: center;
}

.site-layout-header {
  background: #000;
}

.site-layout-content {
  margin: 0px -40px 0px -40px;
  min-height: 100vh;
  padding: 24px;
  background: #f7f7f7;
}

.gridded {
  background: #f7f7f7;
  background-size: 40px 40px;
  background-image:
    linear-gradient(to right, #e5e5e5 1px, transparent 1px),
    linear-gradient(to bottom, #e5e5e5 1px, transparent 1px);
}

.ant-layout-content {
  background: #eee;
}

.site-layout-background {
  background: #eee;
  height: 100vh;
}

.sider {
  overflow: auto;
  height: 100vh;
  background: #eee;
  position: fixed;
}

.right-sider {
  overflow: auto;
  height: 100vh;
  background: #eee;
  position: fixed;
  right: 0;
}

.logo {
  float: left;
  width: 50px;
  height: 31px;
  margin: 18px 24px 20px 0;
  background: transparent;
  color: #ddd;
  border-color: transparent;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
  width: 36px;
  height: 36px;
}

.selected-node {
  fill: #dfa7a7 !important;
}

.selected-item-icon {
  color: #dfa7a7 !important;
}

.highlighted {
  box-shadow: 0 0 10px #eee;
}

.ant-collapse-item:hover {
  box-shadow: 0 0 10px #eee;
}

g .cluster polygon {
  fill: #f2f2f2;
}

g .node polygon {
  fill: #fbfbfb;
}

g polygon {
  fill: transparent;
}

.hidden {
  display: none;
}

.right {
  display: flex;
  justify-content: flex-end;
}

#bashdownload {
  margin-left: 0px; /* ant-btn-group has this set to -1px */
}

.logo-icon {
  max-width: 100%;
  max-height: 99%;
}

.about-img {
  height: 96px;
  width: 96px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
